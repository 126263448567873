import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Virgo.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Virgo Woman
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/virgo"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Virgo</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Virgo Woman</h4>
              
              
             
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Virgo Woman </h1>
                <h2 className="text-sm md:text-base ml-4">July 23 - August 22</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Virgo Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Virgo Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Virgo Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-love");
                  }}>
                  <h5 className="font-semibold text-black">Virgo Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-nature");
                  }}>
                  <h5 className="font-semibold text-black">Virgo Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-man");
                  }}>
                  <h5 className="font-semibold text-black">Virgo Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Virgo Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-traits");
                  }}>
                  <h5 className="font-semibold text-black">Virgo Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-facts");
                  }}>
                  <h5 className="font-semibold text-black">Virgo Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
            The best way to understand how a person thinks and behaves is
                  to look at how they interact with other people. The signs,
                  planets, and elements all play a role in shaping how people
                  interact, which helps to form the overall personality of a
                  person. One of the most important but often overlooked signs
                  that people are born under is their zodiac sign. Let’s
                  understand the Virgo women.
                  <br />
                  <br />
                  <p className="font-bold inline-block text-orange-500">
                    Compatibility signs for Virgo women:
                  </p>{" "}
                  Taurus, Capricorn, Virgo
                  <br />
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">
                    What about Virgo Women’s overall personality?
                  </p>
                  <br/>
                  The Virgo woman is meticulous, hardworking, analytical, and
                  perfectionistic. She is a detail-oriented, tenacious, and
                  efficient person who is comfortable doing things herself. She
                  is a people pleaser who is always willing to help others.
                  <br />
                  <br />
                  However, she also tends to be overbearing and critical when
                  she feels like assisting someone else.
                  <br />
                  They're critical thinkers, which means they can often be heard
                  talking about logic, philosophy, and the Dewey Decimal System.{" "}
                  <br />
                  She is very much defined by their intellect, which is part of
                  the reason they can be such fierce advocates for their
                  beliefs. <br />
                  <br />
                  The sign of Virgo is one of the least apparent and most
                  complex of the characters. Virgo Women is the sign of a
                  critical mind, ideal and detail-orientedness, and a tendency
                  to put things right rather than leave things to nature. It
                  reflects the personality of a cleanliness and tidiness freak,
                  easily offended, and someone who is very conscious of being
                  offended. <br />
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">
                    Virgo women's perfectionist
                  </p>
                  <br/>
                  Virgo women are perfectionists by nature, which often causes
                  them to become over-achievers. Because of this tendency, Virgo
                  women often feel like they need to keep doing better things to
                  please others. <br />
                  It can take a toll on their physical and mental health. They
                  should remember to take time for themselves now and then to
                  recharge and restore their batteries to keep going strong and
                  doing their best work.
                  <br />
                  <br />
                  Over-thinkers: They tend to over-think things and spend a lot
                  of time planning and preparing for events and conversations.
                  It can make them seem anxious and uptight, but it's a defense
                  mechanism. When they feel ready, they can slip into their
                  natural confidence and let their true selves shine.
                  <br />
                  <br />
                  Hard-Worker: They are hardworking by nature, which can
                  sometimes seem uptight and stuffy to other people. While it's
                  true that Virgo women do value hard work and well-ordered
                  life, they also place a lot of importance on being good at
                  their jobs and being a perfectionist. <br />
                  <br />
                  Positive attitude: They are kind-hearted and passionate. They
                  can be intense and neurotic only when stressed or caring for
                  others.
                  <br />
                  <br />
                  She is the first to help others. She is very flexible and
                  adaptable. She is very sympathetic and understanding. She can
                  see the good in all people.
                  <br />
                  <br />
                  Her personality reflects her positive attitude towards other,
                  which sometimes gets them into trouble. They're great
                  strategizers and have a strong sense of purpose and direction,
                  but they can get tunnel-visioned when they're in the thick of
                  it.
                  <br />
                  <br />
                  Virgo women are logical: Virgo women are intensely practical,
                  logical, and systematic. They’re the ones who will quietly
                  plan their strategies before taking action and who will
                  consider all the angles and facts before making a decision.{" "}
                  <br />
                  <br />
                  It can make them seem like they’re not spontaneous or even
                  that fun to be around, but they will stick to it once they’ve
                  made a plan and not change their minds. They may also need a
                  lot of alone time and space to themselves, making them seem
                  aloof or even disinterested in socializing.
                  <br />
                  <br />
                  Virgo women are in love and relationships: Virgo women are
                  often in love. They’re fiercely protective of the ones they
                  love, and they’re often the ones who are willing to sacrifice
                  the most for their relationships. They get incredibly jealous
                  and become enraged when their partner shows signs of being
                  with someone else. But when they fall in love, they fall hard.
                  <br />
                  <br />
                  They are the most loving and affectionate of all the signs.
                  They’re passionate, caring, and deeply committed. They have a
                  hard time being alone, and they need people around them all
                  the time. But when they find the right person, they can be
                  utterly devoted.
                  <br />
                  <br />
                  Virgo women are soulmates. They’re the ones who can make you
                  want to give up on love, and they’re the ones who can make you
                  believe in it again.
                  <br />
                  <br />
                  How to Attract Virgo women? Virgo women are not interested in
                  finding men who are perfect for them. They want to find men
                  who are compatible with them, and they don’t want to see any
                  flaws in their partners.
                  <br />
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">
                    "Virgo women look for responsible, reliable, loving, and
                    intellectual men."
                  </p>
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">Read more about what to do and say to attract a Virgo woman.</p>
                  <br />
                  You've been admiring Virgo women from afar for a while now,
                  and it's high time you started making a move. But before you
                  do that, you should know a few things about her. They're hard
                  to read and often have particular tastes. They're also very
                  down-to-earth and aren't afraid to dirty their hands.
                  <br />
                  <br />
                  If you want to attract the affections of a Virgos, you'll need
                  to demonstrate that you're a suitable match. A Virgo woman is
                  picky when dating, so it's essential to understand what she's
                  looking for. You seems logic in her personality, so if you
                  show that you're a good fit for her, she'll be much more
                  likely to give you a chance. <br />
                  <br />
                  It’s extremely hard to impress, and for a good reason. They
                  are the most self-critical of all the zodiac signs, and they
                  have the highest standards for their partners. But that
                  doesn't mean that you can't try.
                  <br />
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">How do you catch the eye of the most elusive and difficult
                  woman you know? </p><br/>The answer is simple: you have to learn how to
                  be more attractive to her. The good news is that there are
                  many things you can do to improve your appearance and increase
                  your appeal to the opposite sex, regardless of your sign.
                  Virgo women are attracted to confidence, a good sense of
                  humor, and an effortless way of carrying themselves.
                  <br />
                  <br />
                  A Virgo woman is most impressed by the sincerity of other
                  people's efforts. She has an instinct to bring out the best in
                  others. She feels a deep inner love for the people she works
                  with. Virgos are most drawn to those who express a deeply held
                  ideal and are most impressed by the sincerity of that idea.
                  makes time for her, appreciates her intellect, is kind and
                  caring, and is always willing to offer her help. <br />
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">What they want from their partner? </p><br/>He makes time for her,
                  appreciates her intellect, is kind and caring, and is always
                  willing to offer her help. She wants you to be honest and
                  trustworthy. She wants to know why you love her and how she is
                  important to you. She expects you to ask her if she needs any
                  help getting things done. <br />
                  <br />
                  She also believes in efforts and will appreciate it rather
                  than your verbal promises, which may differ from what you’re
                  doing. This gesture may hurt her. She doesn’t expect you to
                  give surprises or take her for candlelit dinner. However, she
                  expects you to do things that she requires. You need to show
                  her respect and care, and she will be yours.
                  <br />
                  <br />
                  You'll need to be different from what she's used to. They can
                  be very set in their ways, and they tend to like things a
                  certain way. By going against what they're used to, you can
                  stand out from the crowd and catch their attention. You don't
                  have to change yourself completely, but a few adjustments will
                  help you stand out.
                  <br />
                  <br />
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
